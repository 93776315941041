import { call, put, takeLatest } from 'redux-saga/effects';
import { importDataTest } from '../../Api/database';
import { importCSVFail, importCSVSuccess } from '../Actions/importCSV';
import { toast } from 'react-toastify';
import IMPORT_CSV from '../Types/importCSV';

function* handleImportCSV(action) {
  try {
    const response = yield call(importDataTest, action.payload);
    const { data } = response;
    if (data.success) {
      yield put(importCSVSuccess());
    } else {
      yield put(importCSVFail({ message: '失敗しました' }));
      toast.error('失敗しました');
    }
  } catch (error) {
    if (error.response?.data?.hashCode === 'CSV_INVALID_DATA') {
      try {
        if (error.response?.data.message) {
          const dt = JSON.parse(error.response.data.message);
          let rs = ['失敗しました'];
          for (const r of dt) {
            if (r?.error) {
              rs.push(r?.error);
            }
          }

          toast.error(rs.join('\n'), {
            enableHtml: true,
            className: 'toast-new-line',
          });
          yield put(importCSVFail({ message: rs.join('\n') }));
          return;
        }
      } catch (e) {
        yield put(importCSVFail({ message: e.response }));
        // TODO
      }
    }
  }
}

export default function* importCSVSaga() {
  yield takeLatest(IMPORT_CSV.IMPORT_CSV, handleImportCSV);
}

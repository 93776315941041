import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, Col, Label, Row } from 'reactstrap';
import { conversionTags } from '../../../Api/conversionTagApi';
import { setScheduleSms } from '../../../Api/userAPI';
import {
  CONVERSION_TAG_URL,
  SCHEDULE_SMS_SENDING_TYPE,
  SCHEDULE_TIMER_OPTIONS,
} from '../../../Common/constant';
import {
  TOAST_MESSAGE,
  scheduleTimerOptions,
} from '../../../Common/constant_text';
import { TEXT_HTML_MANAGEMENT_USER_SEARCH_SMS_LOOP_LINK_MODAL } from '../../../Common/modal_text_info';
import LoadingBar from '../../../Components/LoadingBar';
import { CODE_MAP_DEFINED_API } from '../../../Config/listConstants';
import { loadEnvInfo } from '../../../Redux/Actions/envInfo';
import { loadUserIds } from '../../../Redux/Actions/users';
import Util from '../../../Util/Util';
import RadioGroup from '../../../Components/RadioGroup';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import CustomTimerPicker from '../shared/CustomTimerPicker';
import SearchPresetSelect from '../shared/SearchPresetSelect';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

function SendSmsLoopModal({ visible, onClose, filter }) {
  const [data, setData] = useState({});
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeSend, setTimeSend] = useState(dayjs().valueOf());
  const [scheduleTimerOption, setScheduleTimerOption] = useState(
    SCHEDULE_TIMER_OPTIONS.EVERY_YEAR,
  );
  const [selectedPresets, setSelectedPresets] = useState(null);

  const [contentHtml, setContentHtml] = useState();
  const [conversionTag, setConversionTag] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadEnvInfo());
  }, [dispatch]);

  const envInfo = useSelector((state) => state.envInfo.data);
  const { data: fetchedPresets } = useSelector((state) => state.searchPresets);

  const envInfoTag = envInfo.filter((item) =>
    [
      CODE_MAP_DEFINED_API.SITE_NAME,
      CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS,
      CODE_MAP_DEFINED_API.URL_LP,
      CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER,
      CODE_MAP_DEFINED_API.URL_CONTACT,
    ].includes(item.code),
  );

  useEffect(() => {
    dispatch(loadUserIds(filter));
    conversionTags({}).then((res) => {
      const { data: tags } = res;
      setConversionTag(tags?.data || []);
    });
  }, [dispatch, filter]);

  const onChange = (event) => {
    data[event.target.name] = event.target.value.trim();
    setData(data);
  };

  const handleChangeSelectPresets = (e) => {
    setSelectedPresets(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const { content, label } = data;
    await setScheduleSms({
      sendTime: timeSend,
      type: SCHEDULE_SMS_SENDING_TYPE.FROM_USER_SEARCH,
      userQuery:
        (selectedPresets &&
          fetchedPresets.find((e) => e.id === selectedPresets)?.content) ||
        filter,
      category: +scheduleTimerOption,
      content,
      label,
    })
      .then((response) => {
        onClose();
        toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      })
      .catch((e) => {
        toast.error(TOAST_MESSAGE.ERROR_ADD);
        onClose();
      });
  };

  const handleConfirm = () => {
    if (!data.content) {
      return toast.error('本文してください!');
    }
    if (!data.label) {
      return toast.error('説明テキストしてください!');
    }
    if (!selectedPresets) {
      return toast.error('メモリーの適用してください!');
    }
    replaceUrlPreview(data.content);
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  const handleChangeScheduleTimer = (value) => {
    setTimeSend(value);
  };

  const displaySendTimeToString = () => {
    const { label } = scheduleTimerOptions.find(
      (e) => e.value === +scheduleTimerOption,
    );
    switch (+scheduleTimerOption) {
      case SCHEDULE_TIMER_OPTIONS.EVERY_YEAR:
        return `${label} ${dayjs(timeSend).format('MM/DD HH:mm')}`;
      case SCHEDULE_TIMER_OPTIONS.EVERY_MONTH:
        return `${label} ${dayjs(timeSend).format('DD HH:mm')}`;
      case SCHEDULE_TIMER_OPTIONS.EVERY_WEEK:
        return `${label} ${dayjs(timeSend).format('dddd HH:mm')}`;
      case SCHEDULE_TIMER_OPTIONS.EVERY_DAY:
        return `${label} ${dayjs(timeSend).format('HH:mm')}`;
      default:
        return dayjs(timeSend).format('YYYY/MM/DD HH:mm');
    }
  };

  const displayPresetLabel = () => {
    const item = fetchedPresets.find((e) => e.id === selectedPresets);
    return item?.label;
  };

  const replaceTag = (content, preview, userData) => {
    if (!userData) {
      return content;
    }
    for (const item of conversionTag) {
      const regex = new RegExp(item.tag, 'g');
      if (item.tag === '%SITE_NAME%' && content.indexOf(item.tag) > -1) {
        const description = (envInfoTag ?? []).find(
          (t) => t.code === CODE_MAP_DEFINED_API.SITE_NAME,
        )?.content;
        content = content.replace(
          regex,
          preview ? item.previewMail : `${description}`,
        );
      } else if (item.tag === '%URL_LP%' && content.indexOf(item.tag) > -1) {
        const url = Util.buildLink('/lp');
        const txtLp = preview
          ? item.previewMail || item.contentMail
          : item.contentMail;
        if (txtLp) {
          content = content.replace(regex, txtLp.replace(/#URL#/g, url));
        }
      } else if (
        item.tag === '%MAIL_ADDRESS%' &&
        content.indexOf(item.tag) > -1
      ) {
        const description = (envInfoTag ?? []).find(
          (t) => t.code === CODE_MAP_DEFINED_API.CONTACT_MAIL_ADDRESS,
        )?.content;
        content = content.replace(
          regex,
          preview ? item.previewMail : `${description}`,
        );
      } else if (
        item.tag === '%PHONE_NUMBER%' &&
        content.indexOf(item.tag) > -1
      ) {
        const description = (envInfoTag ?? []).find(
          (t) => t.code === CODE_MAP_DEFINED_API.CONTACT_PHONE_NUMBER,
        )?.content;
        content = content.replace(
          regex,
          preview ? item.previewMail : `${description}`,
        );
      } else if (
        item.tag === '%URL_CONTACT%' &&
        content.indexOf(item.tag) > -1
      ) {
        const url = Util.buildLink(CONVERSION_TAG_URL.URL_CONTACT);
        const txtContact = preview
          ? item.previewMail || item.contentMail
          : item.contentMail;
        if (txtContact) {
          content = content.replace(regex, txtContact.replace(/#URL#/g, url));
        }
      } else {
        content = content.replace(
          regex,
          preview ? item.previewMail || item.contentMail : item.contentMail,
        );
      }
    }

    return content;
  };

  const replaceUrlPreview = (content) => {
    const replaceContent = replaceTag(content, true, {});
    setContentHtml(replaceContent);
    setConFirmVisible(true);
  };

  return (
    <div>
      <LoadingBar show={loading} />
      {confirmVisible && (
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={confirmVisible}
          onClose={onCloseConfirm}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          />
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onCloseConfirm}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent>
            <div style={{ margin: '0.6rem 0 1rem 0' }}>
              {/*実行してよろしいですか？*/}
              <Card body className="mb-4">
                <Row>
                  <Col
                    style={style}
                    xs={6}
                    className={'d-line-content d-flex d-justify-space-between'}
                  >
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <p style={{ paddingLeft: 3 }}>スケジュール</p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'end',
                      }}
                      className={'mr-1'}
                    >
                      <p>{displaySendTimeToString()}</p>
                    </div>
                  </Col>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'end',
                      }}
                      className={'mr-1'}
                    >
                      <span className="d-red-color">スケジュールタイマー</span>
                    </div>
                  </Col>
                </Row>

                <Row className={'d-line-col'}>
                  <Col style={style} xs={6} className={'d-line-content'}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      &nbsp;
                    </div>
                  </Col>
                  <Col
                    style={style}
                    xs={6}
                    className={'d-line-content d-flex d-justify-space-between'}
                  >
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <p style={{ paddingLeft: 3 }}>
                        検索条件メモリーの適用（メモリーを編集しても条件は更新されません）
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'end',
                      }}
                      className={'mr-1'}
                    >
                      {displayPresetLabel()}
                    </div>
                  </Col>
                </Row>
              </Card>
              <Card body className="mb-4">
                <Row>
                  <Label style={style} xs={3}>
                    <p style={{ paddingLeft: 3 }}>説明テキスト</p>
                  </Label>
                  <Label style={style} xs={9}>
                    {data?.label}
                  </Label>
                </Row>
              </Card>
              <Card body className="mb-4">
                <Row>
                  <Label style={style} xs={3}>
                    <p style={{ paddingLeft: 3 }}>本文</p>
                  </Label>
                  <Label style={style} xs={9}>
                    <div
                      dangerouslySetInnerHTML={{ __html: contentHtml }}
                      style={{ minHeight: '150px', whiteSpace: 'pre' }}
                    ></div>
                  </Label>
                </Row>
              </Card>
            </div>
            <div>
              <center style={{ margin: 15 }}>
                <Button
                  onClick={onCloseConfirm}
                  color="primary"
                  className="btn-custom btn-red"
                >
                  いいえ
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  はい
                </Button>
              </center>
            </div>
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>SMS送信（スケジュール）</p>
          </div>
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>

        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{
              __html: TEXT_HTML_MANAGEMENT_USER_SEARCH_SMS_LOOP_LINK_MODAL,
            }}
          ></div>
        </div>
        <DialogContent>
          <Card style={{ marginBottom: 10 }}>
            <Row style={{ padding: 20 }}>
              <Col xs={12}>
                <div style={{ margin: '16px 0 8px 0' }}>
                  <span style={{ color: 'red' }}>*</span>送信文
                </div>
                <textarea
                  rows={12}
                  wrap="hard"
                  style={{
                    width: '100%',
                    marginBottom: '20px',
                    border: '1px solid rgb(118, 118, 118)',
                  }}
                  name="content"
                  onChange={onChange}
                />

                <div className="mt-3 mb-1">
                  <span style={{ color: 'red' }}>*</span>
                  <span>説明テキスト</span>
                </div>
                <textarea
                  name="label"
                  rows={3}
                  onChange={onChange}
                  style={{ width: '100%' }}
                ></textarea>

                <Box width={{ xs: '100%', md: '60%' }} className="mt-3">
                  <SearchPresetSelect
                    isShow={visible}
                    isRequired
                    isHideLoadBtn
                    selectedPresets={selectedPresets}
                    handleChange={handleChangeSelectPresets}
                    titleStyle={{
                      color: '$gray-900',
                      fontWeight: '400',
                      fontSize: '0.875rem',
                      lineHeight: '1.43',
                    }}
                  />
                </Box>
                <div className="mt-3 mb-1 timer-container">
                  <span>スケジュールタイマー</span>
                </div>
                <Box
                  width={{ xs: '100%', md: '60%' }}
                  className="d-flex align-items-center"
                >
                  <RadioGroup
                    controlled
                    defaultValue={scheduleTimerOption}
                    options={scheduleTimerOptions}
                    onChange={(e) => setScheduleTimerOption(e.target.value)}
                    classCustom={styles.customRadioGroup}
                  />
                </Box>
                <Box width={{ xs: '100%', md: '60%' }} className="mt-1">
                  <CustomTimerPicker
                    category={scheduleTimerOption}
                    onChange={handleChangeScheduleTimer}
                  />
                </Box>
              </Col>
            </Row>
          </Card>

          <div style={{ textAlign: 'right', marginBottom: 2 }}>
            {data ? (
              <Button
                onClick={handleConfirm}
                color="primary"
                className="btn-custom btn-blue"
              >
                送信
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'lightgray',
                  color: 'white',
                  cursor: 'no-drop',
                  padding: '4px 36px',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                送信
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SendSmsLoopModal;

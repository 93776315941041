import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import Util from '../../Util/Util';
import SortArrows from '../../Components/SortArrows';
import NoPhoto from '../../assets/image/no_user_photo.png';
import { MuiThemeProvider } from '@material-ui/core';
import { Card, Button } from '@material-ui/core';
import CircularLoading from '../../Components/CircularLoading';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { DISPLAY_CMS_UI_TYPE } from '../../Common/constant';
import SendChatModal from './SendChatModal';
import {
  convertInspectionStatusText,
  convertMemberCategoryTypeText,
} from '../../Util/utils';
import { LINK_MAP_DEFINED_API } from '../../Config/listConstants';
import { toast } from 'react-toastify';
import { loadUserIds } from '../../Redux/Actions/users';

function UserList(props) {
  const {
    users,
    onUpdate,
    filter = { sort: {} },
    onSort = (sortByKey) => {},
    pageIndex,
    pageSize,
    loading,
    totalRow,
    operatorId,
    labelDb,
    EnvSysCms,
  } = props;

  const envUICms = useSelector((state) => state.envUICms.data);
  const admin = useSelector((state) => state.admin.data);
  const nameRank = useSelector((state) => state.nameRank.data);
  const usersAll = useSelector((state) => state.users.itemsUserIds);
  const dispatch = useDispatch();

  const sendChatButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.ADMIN_OPE_SEARCH_USER_SMS_CHAT,
  );
  const [chatVisible, setChatVisible] = useState(false);
  const [userIds, setUsersId] = useState([]);

  useEffect(() => {
    dispatch(loadUserIds(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    const userIds = usersAll.map((item) => item.id);
    setUsersId(userIds);
  }, [usersAll]);

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleClickChat = () => {
    if (EnvSysCms) {
      const mailConfig = EnvSysCms.find((t) => t.content === 'push_max');

      if (userIds?.length > mailConfig?.max) {
        return toast.error('送信数制限内で送信して下さい。制限数：' + mailConfig?.max);
      }
    }

    setChatVisible(true);
  };

  const handleCloseChat = () => {
    setChatVisible(false);
  };

  const checkNameRank = (item) => {
    return nameRank.find((rank) => rank.id === item)?.markWord;
  };
  return (
    <div>
      {chatVisible && (
        <SendChatModal
          visible={chatVisible}
          onClose={handleCloseChat}
          users={users}
          filter={filter}
          operatorId={operatorId}
          userIdsAll={userIds}
        />
      )}
      <React.Fragment>
        <Row style={{ alignItems: 'flex-end' }}>
          <Col xs={6}>
            <h6 className="color-900 ml-3">検索結果 {totalRow}人</h6>
          </Col>
          <Col xs={6} style={{ clear: 'both', marginBottom: 10 }}>
            {(admin?.memberType === 0 ||
              sendChatButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  sendChatButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleClickChat
                    : ''
                }
                className="btn-blue btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {sendChatButton[0]?.content}
                </span>
              </Button>
            )}
          </Col>
        </Row>
        <MuiThemeProvider theme={tableNewTheme}>
          <Card className="scroll-x-on-top">
            {loading ? (
              <CircularLoading />
            ) : (
              <Table padding="checkbox" className="table mb-0">
                <TableBody>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>
                      <SortArrows
                        sortObject={filter.sort}
                        text="電話番号"
                        name="phoneNumber"
                        onClick={onSort}
                      />
                    </TableCell>
                    <TableCell className="text-center">画像</TableCell>
                    <TableCell>
                      <SortArrows
                        sortObject={filter.sort}
                        text={labelDb?.label_nickName?.content}
                        name="nickName"
                        onClick={onSort}
                      />
                    </TableCell>
                    <TableCell>
                      <SortArrows
                        sortObject={
                          filter.sort?.gender && {
                            ...filter.sort,
                            gender:
                              filter.sort?.gender === 'ASC' ? 'DESC' : 'ASC',
                          }
                        }
                        text="性別"
                        name="gender"
                        onClick={onSort}
                      />
                    </TableCell>
                    <TableCell>
                      <SortArrows
                        sortObject={filter.sort}
                        name="states.label"
                        text="都道府県"
                        onClick={onSort}
                      />
                    </TableCell>
                    <TableCell>年齢</TableCell>
                    <TableCell>ランク</TableCell>
                    <TableCell>会員種類</TableCell>
                    <TableCell>会員カテゴリー</TableCell>
                    {/* <TableCell>chara status</TableCell>
                    <TableCell>オペNum</TableCell>
                    <TableCell>会社Num</TableCell> */}
                    <TableCell>
                      <SortArrows
                        sortObject={
                          filter.sort?.ageverification && {
                            ...filter.sort,
                            ageverification:
                              filter.sort?.ageverification === 'ASC'
                                ? 'DESC'
                                : 'ASC',
                          }
                        }
                        name="ageverification"
                        text="年齢認証"
                        onClick={onSort}
                      />
                    </TableCell>
                    <TableCell>
                      <SortArrows
                        sortObject={filter.sort}
                        name="registerDate"
                        onClick={onSort}
                        text="登録日"
                      />
                    </TableCell>
                    <TableCell>
                      <SortArrows
                        sortObject={filter.sort}
                        text="ログイン"
                        name="lastlogin"
                        onClick={onSort}
                      />
                    </TableCell>
                    {/* <TableCell className="text-center">ボタン</TableCell> */}
                  </TableRow>

                  {users.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {(pageIndex - 1) * pageSize + index + 1}
                      </TableCell>
                      <TableCell>{user.phoneNumber}</TableCell>
                      <TableCell className="text-center">
                        <div>
                          <Link
                            to={`/user/${user.id}/detailshort`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => saveLocalStore(user.id)}
                          >
                            <img
                              className="imgRound"
                              src={user.avatar ? user.avatar.url : NoPhoto}
                              alt=""
                            />
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell>{user.nickName}</TableCell>
                      <TableCell>{Util.genderIdToText(user.gender)}</TableCell>
                      <TableCell>{user.states?.label}</TableCell>
                      <TableCell>
                        <time>
                          {Util.calcAge(
                            Util.timestampToDate(user.birthday, 'YYYY-MM-DD'),
                          )}
                        </time>
                      </TableCell>
                      <TableCell>{checkNameRank(user.rank)}</TableCell>
                      <TableCell>
                        {Util.memberTypeToText(user.memberType)}
                      </TableCell>
                      <TableCell>
                        {convertMemberCategoryTypeText(user.memberCategory)}
                      </TableCell>
                      {/* <TableCell>{user.charaStatus}</TableCell>
                      <TableCell>{user.operator?.operatorNumber}</TableCell>
                      <TableCell>{user.agent?.agentNumber}</TableCell> */}
                      <TableCell>
                        {convertInspectionStatusText(+user.ageverification)}
                      </TableCell>
                      <TableCell>
                        <time>
                          {Util.timestampToDate(
                            user.registerDate,
                            'YYYY/MM/DD',
                          )}
                        </time>
                      </TableCell>
                      <TableCell>
                        <time>
                          {Util.timestampToDate(
                            user.lastlogin,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </time>
                      </TableCell>
                      {/* <TableCell className="text-center">
                        {
                          <div>
                            <Link
                              className="d-color-link"
                              to={`/user/${user.id}/edit`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => saveLocalStore(user.id)}
                            >
                              表示
                            </Link>
                          </div>
                        }
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Card>
        </MuiThemeProvider>
      </React.Fragment>
    </div>
  );
}

export default UserList;

import { createAction } from 'redux-actions';
import ScheduleSms from '../Types/scheduleSms';

export const loadScheduleSms = createAction(ScheduleSms.LOAD_SCHEDULE_SMS);
export const loadScheduleSmsSuccess = createAction(
  ScheduleSms.LOAD_SCHEDULE_SMS_SUCCESS,
);
export const loadScheduleSmsFail = createAction(
  ScheduleSms.LOAD_SCHEDULE_SMS_FAIL,
);

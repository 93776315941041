import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SortArrows from '../../../Components/SortArrows';
import Util from '../../../Util/Util';
import { convertTimerSendingTypeText } from '../../../Util/utils';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import { scheduleTimerOptions } from '../../../Common/constant_text';

function ScheduleSmsList({
  logSendSms = [],
  pageIndex,
  pageSize,
  loading,
  filter = { sort: {} },
  onSort = (sortByKey) => {},
  handleClickDetail,
}) {
  return (
    <div className="align-top">
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>設定名</TableCell>
                <TableCell>配信カテゴリー</TableCell>
                <TableCell>次回送信日時</TableCell>
                <TableCell>最終送信日時</TableCell>
                <TableCell>登録日</TableCell>
                <TableCell>送信元ユーザー</TableCell>
                <TableCell>登録環境</TableCell>
                <TableCell>配信中ステイタス</TableCell>
                <TableCell>配信設定</TableCell>
                <TableCell>×送信想定数</TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>

              {logSendSms.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>

                  <TableCell>{item.label}</TableCell>
                  <TableCell>
                    {
                      scheduleTimerOptions.find(
                        (e) => e.value === +item.category,
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {
                      Util.timestampToDate(item.sendTime, 'YYYY/MM/DD HH:mm:ss')
                      // convertTimerSendingTypeText(item?.type)
                    }
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.lastSentDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>{item.sender_id}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.cronjobStatus}</TableCell>
                  <TableCell>{item.sendStatus}</TableCell>
                  <TableCell>{}</TableCell>
                  <TableCell
                    className="d-color-link"
                    style={{ cursor: 'pointer' }}
                  >
                    <Link onClick={() => handleClickDetail(item)}>表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default ScheduleSmsList;

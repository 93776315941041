import {
  APP_DESTINATION,
  BLOG_TYPE,
  CHAT_USER_TYPE,
  CONFIRM_TYPE,
  CONTACT_TYPE,
  DEVICE_TYPE,
  DEVICE_TYPE_IN_LOG,
  DISPLAY_OPTIONS,
  EMAIL_SEND_CATEGORIES,
  GENDER,
  GENDER_CHARACTER,
  HIDDEN_TYPE,
  IMAGE_MEMBER_USER_TYPE,
  INSPECTION_TYPE,
  JOB_ENTRY_MEMBER_LIST_TYPE,
  JOB_PUBLISHING_TYPE,
  LIKE_MATCH_TYPE,
  LOGIN_CONTROL,
  MEMBER_AGENT_TYPE,
  MEMBER_CATEGORY_TYPE,
  MEMBER_SETTING_OPTION_SHOW,
  MEMBER_STATUS,
  MEMBER_TYPE,
  NOTIFY_FILED_TYPES,
  OTP_TYPE,
  PAYMENT_TYPE,
  PLACE_TYPE,
  POINT_USAGE_LOG_TYPE,
  PROF_IMAGE_MAIN_TYPE,
  PROF_IMAGE_SUB_TYPE,
  READ_TYPE,
  REGISTER_STATUS_MEMBER,
  REPLIED_STATUS,
  SCHEDULE_SEND_STATUS_OPTIONS,
  SCHEDULE_TIMER_OPTIONS,
  TIMER_OPTIONS,
  TIMER_SENDING_TYPE,
  TIMER_SENT,
  TIMER_SENT_TYPE,
  TWILIO_RECEIVER_STATUS_TYPE,
  TWILIO_RESULT_TYPE,
  TWILIO_STATUS_TYPE,
} from './constant';
import { IMAGE_STATUS, IMAGE_TYPE } from '../Config/listConstants';

/*ユーザーサイト表示非表示*/
export const memberArray = [
  // { id: 0, name: 'member', value: '全て.', selected: true },
  {
    id: MEMBER_SETTING_OPTION_SHOW.OPTION_SHOW_1,
    name: 'member',
    value: '登録中-Member.',
    selected: false,
  }, // 0
  {
    id: MEMBER_SETTING_OPTION_SHOW.OPTION_SHOW_2,
    name: 'member',
    value: '退会-Member.',
    selected: false,
  }, // 1
];

/*チャット・サポートチャット・ブログ・chat blog*/
export const hiddenStatus = [
  {
    id: HIDDEN_TYPE.NO_HIDDEN,
    name: 'hidden',
    value: '表示中.',
    selected: false,
  }, // 0 Hidden
  {
    id: HIDDEN_TYPE.HIDDEN,
    name: 'hidden',
    value: '非表示中.',
    selected: false,
  }, // 1 Not Hidden
];

export const jobPublishingType = [
  {
    id: JOB_PUBLISHING_TYPE.DRAFT,
    name: 'hidden',
    value: '下書き中.',
    selected: false,
  }, // 0 Drat
  {
    id: JOB_PUBLISHING_TYPE.PUBLISHING,
    name: 'hidden',
    value: '掲載中.',
    selected: false,
  }, // 1 Publishing
];

/*年齢認証・チャット・ブログ*/
export const inspectionStatus = [
  {
    id: INSPECTION_TYPE.NO_INSPECTION,
    name: 'inspection',
    value: '未認証.',
    selected: false,
  }, // 0 Not Inspection
  {
    id: INSPECTION_TYPE.INSPECTION,
    name: 'inspection',
    value: '認証済.',
    selected: false,
  }, // 1 Inspection
];

/*SMS電話認証済*/
export const registerStatus = [
  {
    id: REGISTER_STATUS_MEMBER.NO_VERIFY_PHONE_NUMBER,
    name: 'registerStatus',
    value: '電話未認証.',
    selected: false,
  },
  {
    id: REGISTER_STATUS_MEMBER.VERIFY_PHONE_NUMBER,
    name: 'registerStatus',
    value: '電話認証済.',
    selected: false,
  },
];

/*プロフ画像専用認証*/
export const imageProfStatus = [
  {
    id: IMAGE_STATUS.PENDING,
    name: 'imageProfStatus',
    value: '未認証-プロフ画像.',
    selected: false,
  }, // 1 PENDING
  {
    id: IMAGE_STATUS.CONFIRMED,
    name: 'imageProfStatus',
    value: '認証済-プロフ画像.',
    selected: false,
  }, // 2 CONFIRMED
  {
    id: IMAGE_STATUS.ALL,
    name: 'imageProfStatus',
    value: '認証-プロフ画像（全て）.',
    selected: false,
  }, // 3 all
];

export const confirmTypes = [
  {
    id: CONFIRM_TYPE.UN_CONFIRMED,
    name: 'confirmTypes',
    value: '未確認.',
    selected: false,
  }, // 0 UN_CONFIRMED
  {
    id: CONFIRM_TYPE.CONFIRMED,
    name: 'confirmTypes',
    value: '確認済.',
    selected: false,
  }, // 1 CONFIRMED
];

export const sexArray = [
  // { id: 0, name: 'sex', value: '全て.', selected: true },
  { id: GENDER.MALE, name: 'sex', value: '男性.', selected: false }, // 1
  { id: GENDER.FEMALE, name: 'sex', value: '女性.', selected: false }, // 2
];

export const genderCharacters = [
  {
    id: GENDER_CHARACTER.MALE_CHAR,
    name: 'genderCharacters',
    value: '男性キャラ.',
    selected: false,
  }, // 3
  {
    id: GENDER_CHARACTER.FEMALE_CHAR,
    name: 'genderCharacters',
    value: '女性キャラ.',
    selected: false,
  }, // 4
];

export const charaStatus = [
  {
    id: MEMBER_STATUS.USER,
    name: 'chara_status',
    value: 'ユーザー.',
    selected: false,
  }, // 0
  {
    id: MEMBER_STATUS.CHARA,
    name: 'chara_status',
    value: 'キャラクター.',
    selected: false,
  }, // 1
];

/*会員種類ｙ*/
export const memberTypeArray = [
  {
    id: MEMBER_TYPE.NORMAL,
    name: 'memberType',
    value: '普通会員.',
    selected: false,
  }, // 1
  {
    id: MEMBER_TYPE.PONT_USER,
    name: 'memberType',
    value: 'ポイント会員.',
    selected: false,
  }, // 2
  {
    id: MEMBER_TYPE.PLAN_USER,
    name: 'memberType',
    value: 'プラン会員.',
    selected: false,
  }, // 3
  {
    id: MEMBER_TYPE.JOB_TRIAL,
    name: 'memberType',
    value: '仕事お試し.',
    selected: false,
  }, // 5
  {
    id: MEMBER_TYPE.JOB_MEMBER,
    name: 'memberType',
    value: '仕事会員.',
    selected: false,
  }, // 6
];

export const jobEntryMemberType = [
  {
    id: JOB_ENTRY_MEMBER_LIST_TYPE.TRAIL,
    name: 'jobEntryMemberType',
    value: 'お試し登録（仕事）.',
    selected: false,
  }, // 1
  {
    id: JOB_ENTRY_MEMBER_LIST_TYPE.ENTRY,
    name: 'jobEntryMemberType',
    value: '本登録（仕事）.',
    selected: false,
  }, // 2
];

export const memberAgentType = [
  {
    id: MEMBER_AGENT_TYPE.NORMAL,
    name: 'memberAgentType',
    value: '会社-仮会員.',
    selected: false,
  }, // 1
  {
    id: MEMBER_AGENT_TYPE.PLAN,
    name: 'memberAgentType',
    value: '会社-本会員.',
    selected: false,
  }, // 2
];

/*ユーザーカテゴリー（Web・Job）*/
export const memberCategoryType = [
  {
    id: MEMBER_CATEGORY_TYPE.WEB_MATCH,
    name: 'memberCategoryType',
    value: 'WebMatch.',
    selected: false,
  }, // 1
  {
    id: MEMBER_CATEGORY_TYPE.JOB,
    name: 'memberCategoryType',
    value: 'Job.',
    selected: false,
  }, // 2
];

/*memberImageType*/
export const memberImageType = [
  {
    id: IMAGE_MEMBER_USER_TYPE.ALL,
    name: 'memberImageType',
    value: 'メイン画像+サブ画像.',
    selected: false,
  }, // 3
  {
    id: IMAGE_MEMBER_USER_TYPE.MAIN,
    name: 'memberImageType',
    value: 'メイン画像.',
    selected: false,
  }, // 1
  {
    id: IMAGE_MEMBER_USER_TYPE.SUB,
    name: 'memberImageType',
    value: 'サブ画像.',
    selected: false,
  }, // 2
];

/*ポイント消費・電話番号変更*/
export const typeConnection = [
  {
    id: DEVICE_TYPE_IN_LOG.WEB,
    name: 'typeConnection',
    value: 'Web端末.',
    selected: false,
  }, // 0
  {
    id: DEVICE_TYPE_IN_LOG.APP,
    name: 'typeConnection',
    value: 'App端末.',
    selected: false,
  }, // 1
];

/*ユーザー登録環境*/
export const registerType = [
  { id: 1, name: 'registerType', value: 'WEB登録.', selected: false },
  { id: 2, name: 'registerType', value: 'APP登録.', selected: false },
  { id: 9, name: 'registerType', value: '管理登録.', selected: false },
  { id: 3, name: 'registerType', value: 'CSV登録.', selected: false },
];

/*SMSパスワードログイン方法*/
export const needOtp = [
  { id: OTP_TYPE.OTP, name: 'needOtp', value: 'SMSログイン.', selected: false }, // 0
  {
    id: OTP_TYPE.NO_OTP,
    name: 'needOtp',
    value: 'パスワードログイン.',
    selected: false,
  }, // 1
];

/*ユーザーログイン許可*/
export const loginControlArray = [
  {
    id: LOGIN_CONTROL.ACCEPT,
    name: 'logincontrol',
    value: 'ログイン許可.',
    selected: false,
  },
  {
    id: LOGIN_CONTROL.NO_ACCEPT,
    name: 'logincontrol',
    value: 'ログイン禁止.',
    selected: false,
  },
];

/*チャット・サポートチャット*/
export const readStatus = [
  { id: READ_TYPE.NO_READ, name: 'read', value: '未読.', selected: false }, // 0 Un Read
  { id: READ_TYPE.READ, name: 'read', value: '既読.', selected: false }, // 1 Read
];

/*チャット・サポートチャット*/
export const repliedStatus = [
  {
    id: REPLIED_STATUS.NOT_REPLIED,
    name: 'replied',
    value: '未返信.',
    selected: false,
  }, // 0 Not Reply
  {
    id: REPLIED_STATUS.REPLIED,
    name: 'replied',
    value: '返信済.',
    selected: false,
  }, // 1 Reply
];

/*ポイント消費*/
export const usagePointType = [
  {
    id: POINT_USAGE_LOG_TYPE.UNKNOWN,
    name: 'usagePointType',
    value: 'その他.',
    selected: false,
  }, // 0
  {
    id: POINT_USAGE_LOG_TYPE.CHAT_SEND,
    name: 'usagePointType',
    value: 'チャット送信.',
    selected: false,
  }, // 1
  {
    id: POINT_USAGE_LOG_TYPE.CHAT_RECEIVE,
    name: 'usagePointType',
    value: 'チャット受信.',
    selected: false,
  }, // 2
  {
    id: POINT_USAGE_LOG_TYPE.PHOTO_SEND,
    name: 'usagePointType',
    value: '画像送信.',
    selected: false,
  }, // 3
  {
    id: POINT_USAGE_LOG_TYPE.PHOTO_RECEIVE,
    name: 'usagePointType',
    value: '画像受信.',
    selected: false,
  }, // 4
  {
    id: POINT_USAGE_LOG_TYPE.PROFILE_VIEW,
    name: 'usagePointType',
    value: 'プロフ表示.',
    selected: false,
  }, // 5
  {
    id: POINT_USAGE_LOG_TYPE.PHOTO_UPDATE,
    name: 'usagePointType',
    value: '写真更新.',
    selected: false,
  }, // 6
  {
    id: POINT_USAGE_LOG_TYPE.LIKE_SEND,
    name: 'usagePointType',
    value: 'いいね送信.',
    selected: false,
  }, // 7
  {
    id: POINT_USAGE_LOG_TYPE.CALL_TALK,
    name: 'usagePointType',
    value: '音声会話.',
    selected: false,
  }, // 8
  {
    id: POINT_USAGE_LOG_TYPE.CALL_VIDEO,
    name: 'usagePointType',
    value: '画像会話.',
    selected: false,
  }, // 9
  {
    id: 81,
    name: 'usagePointType',
    value: 'ポイントプレゼント.',
    selected: false,
  }, // 81
];

/*課金*/
export const paymentTypeArray = [
  {
    id: PAYMENT_TYPE.BANK_TRANSFER,
    name: 'paymentType',
    value: '銀行振込.',
    selected: false,
  }, // 1
  {
    id: PAYMENT_TYPE.CREDIT_PAYMENT,
    name: 'paymentType',
    value: 'クレジット.',
    selected: false,
  }, // 11
  {
    id: PAYMENT_TYPE.GOOGLE_PAY,
    name: 'paymentType',
    value: 'GooglePay.',
    selected: false,
  }, // 21
  {
    id: PAYMENT_TYPE.ITUNE_STORE,
    name: 'paymentType',
    value: 'ItuneStore.',
    selected: false,
  }, // 31
  {
    id: PAYMENT_TYPE.CONVENIENCE_STORE,
    name: 'paymentType',
    value: 'コンビニ決済.',
    selected: false,
  }, // 41
  {
    id: PAYMENT_TYPE.GIFT_POINT,
    name: 'paymentType',
    value: 'ポイントプレゼント.',
    selected: false,
  }, // 81
  {
    id: PAYMENT_TYPE.BONUS,
    name: 'paymentType',
    value: 'ボーナスポイント.',
    selected: false,
  }, // 91
  {
    id: PAYMENT_TYPE.UNKNOWN,
    name: 'paymentType',
    value: 'その他.',
    selected: false,
  }, // 0
];

/*課金*/
export const payingTypeArray = [
  { id: 1, name: 'payingType', value: 'ポイント購入.', selected: false },
  { id: 2, name: 'payingType', value: 'プラン購入.', selected: false },
  { id: 9, name: 'payingType', value: 'その他購入.', selected: false },
];

/*問い合わせ*/
export const contactTypes = [
  {
    id: CONTACT_TYPE.BEFORE_LOGIN,
    name: 'contactType',
    value: 'ログイン前.',
    selected: false,
  }, // 1
  {
    id: CONTACT_TYPE.AFTER_LOGIN,
    name: 'contactType',
    value: 'ログイン後.',
    selected: false,
  }, // 2
  {
    id: CONTACT_TYPE.AGE_VERIFICATION,
    name: 'contactType',
    value: '年齢認証.',
    selected: false,
  }, // 3
];

/*タイマー設定*/
export const displayOptions = [
  {
    id: DISPLAY_OPTIONS.OFF,
    name: 'displayOptions',
    value: 'タイマー設定OFF.',
    selected: false,
  }, // 1
  {
    id: DISPLAY_OPTIONS.ON,
    name: 'displayOptions',
    value: 'タイマー設定ON.',
    selected: false,
  }, // 0
];

/*メール有効*/
export const emailAddressMainStatus = [
  {
    id: 0,
    name: 'emailAddressMainStatus',
    value: 'メール不着.',
    selected: false,
  },
  {
    id: 1,
    name: 'emailAddressMainStatus',
    value: 'メール有効.',
    selected: false,
  },
];

export const hasDepositedOption = [
  {
    id: 0,
    name: 'hasDepositedOption',
    value: '全て.',
    selected: true,
  },
  {
    id: 1,
    name: 'hasDepositedOption',
    value: '前日無.',
    selected: false,
  },
  {
    id: 2,
    name: 'hasDepositedOption',
    value: '前日有.',
    selected: false,
  },
  {
    id: 3,
    name: 'hasDepositedOption',
    value: '当日無.',
    selected: false,
  },
  {
    id: 4,
    name: 'hasDepositedOption',
    value: '当日有.',
    selected: false,
  },
];

/*ユーザーTwilio転送許可*/
export const standBy = [
  { id: 0, name: 'standBy', value: '非待機.', selected: false },
  { id: 1, name: 'standBy', value: '待機中.', selected: false },
  { id: 2, name: 'standBy', value: '会話中.', selected: false },
];

/*いいね成立*/
export const likeMatchTypes = [
  {
    id: LIKE_MATCH_TYPE.NO_MATCH,
    name: 'likeMatchTypes',
    value: '不成立.',
    selected: false,
  }, // 0
  {
    id: LIKE_MATCH_TYPE.MATCH,
    name: 'likeMatchTypes',
    value: '成立.',
    selected: false,
  }, // 1
];

/*予約送信*/
export const timerSendingTypes = [
  {
    id: TIMER_SENDING_TYPE.FROM_USER_DETAIL,
    name: 'timerSendingTypes',
    value: '個別画面.',
    selected: false,
  }, // 1
  {
    id: TIMER_SENDING_TYPE.FROM_USER_SEARCH,
    name: 'timerSendingTypes',
    value: '検索画面.',
    selected: false,
  }, // 2
  {
    id: TIMER_SENDING_TYPE.FROM_AGENT_DETAIL,
    name: 'timerSendingTypes',
    value: '会社画面.',
    selected: false,
  }, // 3
];

/*チャット・サポートチャット種類*/
export const chatUserTypes = [
  {
    id: CHAT_USER_TYPE.STYLE_1,
    name: 'chatUserTypes',
    value: 'テキスト.',
    selected: false,
  }, // 1
  {
    id: CHAT_USER_TYPE.STYLE_2,
    name: 'chatUserTypes',
    value: '画像.',
    selected: false,
  }, // 2
];

/*プロフ画像種類（メイン・サブ）*/
export const imageTypes = [
  {
    id: IMAGE_TYPE.AVATAR,
    name: 'imageTypes',
    value: 'メインプロフ画像.',
    selected: false,
  }, // 1
  {
    id: IMAGE_TYPE.SUB_IMAGE,
    name: 'imageTypes',
    value: 'サブプロフ画像.',
    selected: false,
  }, // 2
];

/*ブログ種類（テキスト・画像）*/
export const blogType = [
  {
    id: BLOG_TYPE.TEXT,
    name: 'blogType',
    value: 'テキストブログ.',
    selected: false,
  }, // 1
  {
    id: BLOG_TYPE.IMAGE,
    name: 'blogType',
    value: '画像ブログ.',
    selected: false,
  }, // 2
];

/*コメント*/
export const profImageMainTypes = [
  {
    id: PROF_IMAGE_MAIN_TYPE.STYLE,
    name: 'profImageTypes',
    value: 'メイン画像.',
    selected: false,
  }, // , main
];

/*コメント*/
export const profImageSubTypes = [
  {
    id: PROF_IMAGE_SUB_TYPE.STYLE_1,
    name: 'profImageTypes',
    value: 'サブ画像-1.',
    selected: false,
  }, // 1, sub
  {
    id: PROF_IMAGE_SUB_TYPE.STYLE_2,
    name: 'profImageTypes',
    value: 'サブ画像-2.',
    selected: false,
  }, // 2, sub
  {
    id: PROF_IMAGE_SUB_TYPE.STYLE_3,
    name: 'profImageTypes',
    value: 'サブ画像-3.',
    selected: false,
  }, // 3, sub
  {
    id: PROF_IMAGE_SUB_TYPE.STYLE_4,
    name: 'profImageTypes',
    value: 'サブ画像-4.',
    selected: false,
  }, // 4, sub
  {
    id: PROF_IMAGE_SUB_TYPE.STYLE_5,
    name: 'profImageTypes',
    value: 'サブ画像-5.',
    selected: false,
  }, // 5, sub
];

export const twilioStatusTypes = [
  {
    id: TWILIO_STATUS_TYPE.PREPARE_TO_CALL,
    name: 'twilioStatusTypes',
    value: '発信準備.',
    selected: false,
  }, // 0
  {
    id: TWILIO_STATUS_TYPE.CALL_START,
    name: 'twilioStatusTypes',
    value: '発信開始.',
    selected: false,
  }, // 1
  {
    id: TWILIO_STATUS_TYPE.IN_CONVERSATION,
    name: 'twilioStatusTypes',
    value: '会話中.',
    selected: false,
  }, // 2
  {
    id: TWILIO_STATUS_TYPE.END_CALL,
    name: 'twilioStatusTypes',
    value: '通話終了.',
    selected: false,
  }, // 3
  {
    id: TWILIO_STATUS_TYPE.NULL,
    name: 'twilioStatusTypes',
    value: '空欄.',
    selected: false,
  }, // 4
  {
    id: TWILIO_STATUS_TYPE.OTHER,
    name: 'twilioStatusTypes',
    value: 'その他.',
    selected: false,
  }, // 9
];

export const twilioResultTypes = [
  {
    id: TWILIO_RESULT_TYPE.COMPLETED,
    name: 'twilioResultTypes',
    value: '終了.',
    selected: false,
  }, // completed
  {
    id: TWILIO_RESULT_TYPE.NO_ANSWER,
    name: 'twilioResultTypes',
    value: '応答なし.',
    selected: false,
  }, // no-answer
  {
    id: TWILIO_RESULT_TYPE.BUSY,
    name: 'twilioResultTypes',
    value: 'ビジー.',
    selected: false,
  }, // busy
  {
    id: TWILIO_RESULT_TYPE.NULL,
    name: 'twilioResultTypes',
    value: '空欄.',
    selected: false,
  }, // Null
];

export const twilioReceiverStatusTypes = [
  {
    id: TWILIO_RECEIVER_STATUS_TYPE.OK,
    name: 'twilioReceiverStatusTypes',
    value: '会話承諾.',
    selected: false,
  }, // 1
  {
    id: TWILIO_RECEIVER_STATUS_TYPE.NG,
    name: 'twilioReceiverStatusTypes',
    value: '会話拒否.',
    selected: false,
  }, // 2
  {
    id: TWILIO_RECEIVER_STATUS_TYPE.NULL,
    name: 'twilioReceiverStatusTypes',
    value: '空欄.',
    selected: false,
  }, // 3
];

export const timerSends = [
  {
    id: TIMER_SENT.WAITING,
    name: 'timerSends',
    value: '予約待機中.',
    selected: false,
  }, // 0
  {
    id: TIMER_SENT.SENT,
    name: 'timerSends',
    value: '送信済.',
    selected: false,
  }, // 1
];

export const timerSentTypes = [
  {
    id: TIMER_SENT_TYPE.SEND_INDIVIDUALLY,
    name: 'timerSentTypes',
    value: '個別送信.',
    selected: false,
  }, // 1
  {
    id: TIMER_SENT_TYPE.BROADCAST,
    name: 'timerSentTypes',
    value: '一斉送信.',
    selected: false,
  }, // 2
];

export const scheduleTimerOptions = [
  { value: SCHEDULE_TIMER_OPTIONS.EVERY_YEAR, label: '毎年.' },
  { value: SCHEDULE_TIMER_OPTIONS.EVERY_MONTH, label: '毎月.' },
  { value: SCHEDULE_TIMER_OPTIONS.EVERY_WEEK, label: '毎週.' },
  { value: SCHEDULE_TIMER_OPTIONS.EVERY_DAY, label: '毎日.' },
];

export const scheduleSendStausOptions = [
  { value: SCHEDULE_SEND_STATUS_OPTIONS.SEND, label: '表示中' },
  { value: SCHEDULE_SEND_STATUS_OPTIONS.UNSEND, label: '非表示中' },
];

export const emailSendCategory = [
  {
    id: EMAIL_SEND_CATEGORIES.MEMBER,
    name: 'emailSendCategory',
    value: 'ユーザー.',
    selected: false,
  }, // 1
  {
    id: EMAIL_SEND_CATEGORIES.AGENT,
    name: 'emailSendCategory',
    value: '会社.',
    selected: false,
  }, // 2
];

export const placeOptions = [
  { value: PLACE_TYPE.LEFT, label: 'マイページ左.' }, // 1
  { value: PLACE_TYPE.RIGHT, label: 'マイページ右.' }, // 2
];

const objectKeyNoti = {
  [NOTIFY_FILED_TYPES.MAIL_INFO_SUPPORT]: {
    id: NOTIFY_FILED_TYPES.MAIL_INFO_SUPPORT,
    val: '1',
    name: 'mailInfoSupport',
    value: 'MAIL_サイトお知らせ.',
    selected: true,
  },
  [NOTIFY_FILED_TYPES.MAIL_CHAT_USER]: {
    id: NOTIFY_FILED_TYPES.MAIL_CHAT_USER,
    val: '1',
    name: 'mailChatUser',
    value: 'MAIL_通知チャットユーザー.',
    selected: true,
  },
  [NOTIFY_FILED_TYPES.SMS_INFO_SUPPORT]: {
    id: NOTIFY_FILED_TYPES.SMS_INFO_SUPPORT,
    val: '1',
    name: 'smsInfoSupport',
    value: 'SMS_サイトお知らせ.',
    selected: true,
  },
  [NOTIFY_FILED_TYPES.SMS_CHAT_USER]: {
    id: NOTIFY_FILED_TYPES.SMS_CHAT_USER,
    val: '1',
    name: 'smsChatUser',
    value: 'SMS_通知チャットユーザー.',
    selected: true,
  },
  [NOTIFY_FILED_TYPES.PUSH_CHAT]: {
    id: NOTIFY_FILED_TYPES.PUSH_CHAT,
    val: '1',
    name: 'pushChat',
    value: 'アプリPush_メッセージ.',
    selected: true,
  },
  [NOTIFY_FILED_TYPES.PUSH_LIKE]: {
    id: NOTIFY_FILED_TYPES.PUSH_LIKE,
    val: '1',
    name: 'pushLike',
    value: 'アプリPush_いいね.',
    selected: true,
  },
  [NOTIFY_FILED_TYPES.PUSH_INFO]: {
    id: NOTIFY_FILED_TYPES.PUSH_INFO,
    val: '1',
    name: 'pushInfo',
    value: 'アプリPush_お知らせ.',
    selected: true,
  },
};
export const settingNotify = Object.values(NOTIFY_FILED_TYPES).map((value) => {
  return objectKeyNoti[value];
});

export const AppDestinationOptions = [
  { value: APP_DESTINATION.NONE, label: '' }, // 0
  { value: APP_DESTINATION.DEST_1, label: 'プラン購入.' }, // 1
  { value: APP_DESTINATION.DEST_2, label: 'ポイント購入.' }, // 2
  { value: APP_DESTINATION.DEST_3, label: '問い合わせ（既存）.' }, // 3
  { value: APP_DESTINATION.DEST_4, label: '問い合わせ（年齢認証）.' }, // 4
  { value: APP_DESTINATION.DEST_11, label: 'お知らせ－１.' }, // 11
  { value: APP_DESTINATION.DEST_12, label: 'お知らせ－２.' }, // 12
  { value: APP_DESTINATION.DEST_13, label: 'お知らせ－３.' }, // 13
  { value: APP_DESTINATION.DEST_14, label: 'お知らせ－４.' }, // 14
  { value: APP_DESTINATION.DEST_15, label: 'お知らせ－５.' }, // 15
  { value: APP_DESTINATION.DEST_16, label: 'お知らせ－６.' }, // 16
  { value: APP_DESTINATION.DEST_17, label: 'お知らせ－７.' }, // 17
  { value: APP_DESTINATION.DEST_18, label: 'お知らせ－８.' }, //18
];

export const TOAST_MESSAGE = {
  SUCCESS_ADD: '新規追加しました.',
  SUCCESS_EDIT: '更新しました.',
  SUCCESS_DELETE: '削除しました.',
  SUCCESS_CLEAR_DATA: '件削除しました.',
  SUCCESS_CLEAR_FOLDER_IMAGE: 'SUCCESS_CLEAR_FOLDER_IMAGE.',

  ERROR_ADD: '新規追加に失敗しました.',
  ERROR_EDIT: '更新に失敗しました.',
  ERROR_DELETE: '削除に失敗しました.',
  ERROR_CLEAR_DATA: '件削除に失敗しました.',
  ERROR_CLEAR_FOLDER_IMAGE: 'ERROR_CLEAR_FOLDER_IMAGE.',

  VALIDATE_REQUIRED: {
    phoneNumber: '電話番号を入力して下さい.',
    password: 'パスワードを入力して下さい.',
    email: 'メールアドレスを入力して下さい.',
    nickname: 'ニックネームを入力して下さい.',
    state: '都道府県を入力して下さい.',
    gender: '性別を入力して下さい.',
    operatorNumber: 'オペレーターNumberを入力して下さい.',
    agencyNumber: '会社Numberを入力して下さい.',
    birthday: '誕生日を選択して下さい.',
    character: 'キャラクターを選択して下さい.',
    ageVerification: '年齢認証を選択して下さい.',
    memberStatus: '表示ステイタスを選択して下さい.',
    memberType: '会員種類を選択して下さい.',
    memberCategoryType: 'require memberCategoryType',
    needOtp: 'ログインSMS必要を選択して下さい.',
    password810: 'パスワード（英数字8文字～10文字）を入力して下さい.',
    depositpoint: 'ポイントを入力して下さい.',
    salarypoint: '給与ポイントを入力して下さい.',
    operator: {
      name: 'オペレーター名を入力して下さい.',
    },
    agent: {
      name: '会社名を入力して下さい.',
    },
    memberAdmin: {
      name: '名前を入力して下さい.',
    },
    registerSatus: '電話認証状態を選択して下さい.',
  },
  VALIDATE_FORMAT: {
    phoneNumber: '電話番号の形式が正しくありません.',
    passwordNumber6: 'パスワードは数字6桁で入力して下さい.', //ユーザー登録編集
    email: 'メールアドレスの形式が正しくありません.',
    operatorNumber:
      'オペレーターNumberの形式が正しくありません\n（英数字のみ）.', //オペレーター登録編集
    agencyNumber: '会社Numberの形式が正しくありません\n（英数字のみ）.', //会社登録編集
    birthday18: '18歳未満のユーザー登録はできません.',
    password810: 'パスワードは英数字8文字～10文字で入力して下さい.', //管理オペ会社登録編集
  },
  INFO: {
    operatorNumber: 'ユーザーはオペーレーターNumberを選べません.',
    agentNumber: "ユーザーは'会社Numberを選べません.",
  },
};

export const MESSAGE_CONFIRM_DELETE = {
  TITLE: '削除確認画面.',
  CONTENT: '削除してよろしいですか？.',
};

export const errorApiText = {
  AGENT_FILE_INDEX_NOT_FOUND: 'インデックスファイルは存在しません.',
  AGENT_PATH_AGENT_LP_EMPTY: 'URLのパスを入力して下さい.',
  AGENT_PATH_AGENT_LP_EXISTING: '入力したURLのパスは既に存在しています.',
  AGENT_AGENT_LP_ERROR: 'エラーが発生しました。再度ご確認下さい.',
  LOGIN_CONTROL_NO_ACCEPT:
    'ログインが制限されています、問い合わせからお問い合わせ下さい.',
};

export const optionsSearchLastLogin = [
  { value: 0, label: 'ログイン履歴有.' },
  { value: 1, label: 'ログイン履歴無.' },
];

export const USER_DETAIL_MESSAGES = {
  COPY_ID_SUCCESS: 'コピーしました.',
  COPY_ID_FAILED: 'コピーに失敗しました.',
};

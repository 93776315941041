import React, { forwardRef, useState, useEffect } from 'react';
import DatePicker from './DatePicker';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import { FormLabel } from '@material-ui/core';
import moment from 'moment';

let hour = [];
for (let index = 0; index < 24; index++) {
  hour.push({
    value: index,
    displayValue: index < 10 ? `0${index}` : `${index}`,
  });
}

const DateTimePicker = forwardRef(function (
  { name, title, titleStyle, timestamp, onChange },
  ref,
) {
  const [dateState, setDateState] = useState(
    moment(+timestamp).format('YYYY-MM-DD'),
  );
  const [hourState, setHourState] = useState(moment(+timestamp).hour());
  const [minuteState, setMinuteState] = useState(
    moment(+timestamp).minute().toString(),
  );

  const handleDate = (e) => {
    const value = moment(e.target.value).format('YYYY-MM-DD');
    setDateState(value);

    let time = moment(`${value} ${hourState}:${minuteState}:00`)
      .valueOf()
      .toString();
    onChange(name, time);
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourState(value);
    let time = moment(`${dateState} ${value}:${minuteState}:00`)
      .valueOf()
      .toString();
    onChange(name, time);
  };

  const handleMinute = (e) => {
    let value = e.target.value;
    setMinuteState(value);
    let time = moment(`${dateState} ${hourState}:${value}:00`)
      .valueOf()
      .toString();
    onChange(name, time);
  };

  return (
    <React.Fragment>
      <FormGroup row>
        <Col xs={12}>
          <FormLabel component="legend" style={titleStyle}>
            {title}
          </FormLabel>
        </Col>

        <Col className="datePicker" xs={12}>
          <DatePicker
            className="date"
            defaultValue={new Date(dateState)}
            onChange={(e) => handleDate(e)}
            locale="ja"
            dateFormat="yyyy/MM/dd"
          />
          <select
            value={hourState}
            onChange={(e) => handleHour(e)}
            className="hour"
          >
            {hour.map((e) => (
              <option key={e.value} value={e.value}>
                {e.displayValue}
              </option>
            ))}
          </select>
          <select
            value={minuteState}
            onChange={(e) => handleMinute(e)}
            className="minus"
          >
            <option value="00">00</option>
            <option value="30">30</option>
          </select>
        </Col>
      </FormGroup>
    </React.Fragment>
  );
});

export default DateTimePicker;

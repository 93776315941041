import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SearchBox from '../SearchBox';
import Button from '@material-ui/core/Button';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
});

export default function PresetsPreview({
  open,
  onClose,
  handleSubmit,
  formData,
  isSubmitBtnDisabled,
}) {
  const { label: title, displayOrder, content: previewData } = formData;

  const closePresetsPreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  const handleSubmitBtn = (e) => {
    handleSubmit();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      // aria-labelledby="max-width-dialog-title"
      className={'d-modal-wrapper'}
    >
      <MuiThemeProvider theme={theme}>
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          <p className={'d-title mb-0'}>{`${title} - ${displayOrder}`}</p>
          <div>
            <Button
              onClick={(e) => closePresetsPreview(e)}
              color="primary"
              className="btn-custom btn-red"
            >
              キャンセル
            </Button>
            <Button
              disabled={isSubmitBtnDisabled}
              onClick={handleSubmitBtn}
              color="primary"
              className="btn-custom btn-blue mr-3"
              style={isSubmitBtnDisabled && { opacity: '0.7' }}
            >
              保存
            </Button>
          </div>
        </DialogTitle>
      </MuiThemeProvider>
      <DialogContent>
        <div style={{ pointerEvents: 'none' }}>
          {<SearchBox previewData={previewData} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link, useHistory } from 'react-router-dom';
import Util from '../../Util/Util';
import SortArrows from '../../Components/SortArrows';
import NoPhoto from '../../assets/image/no_user_photo.png';
import UserDelete from '../../assets/image/delete_user.png';
import SendSmsModal from './SendSmsModal';
import { Button, Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { Col, Row } from 'reactstrap';
import SendEmailModal from './SendEmailModal';
import { useDispatch, useSelector } from 'react-redux';
import { DISPLAY_CMS_UI_TYPE, MEMBER_USER_STATUS } from '../../Common/constant';
import SendNotiModal from './SendNotiModal.js';
import { getUsers, getUserExport } from '../../Api/userAPI';
import { getEnvSysCms } from '../../Api/EnvSysCmsApi';
import { toast } from 'react-toastify';
import {
  convertCharaStatusText,
  convertInspectionStatusText,
  convertMemberCategoryTypeText,
} from '../../Util/utils';
import { LINK_MAP_DEFINED_API } from '../../Config/listConstants';
import { CSVLink } from 'react-csv';
import RemoveMultipleUsersModal from '../UserManagement/UserDetail/RemoveMulitpleUsersModal.js';
import FileUploadModal from '../../Components/FileUploadModal.js';
import SendEmailLoopModal from './SendEmailLoopModal/index.js';
import { importDataTest } from '../../Api/database.js';
import SendSmsLoopModal from './SendSmsLoopModal/index.js';
import ConfirmImportModal from './ImportCsvSubModal/ConfirmImportModal.jsx';
import { importCSV } from '../../Redux/Actions/importCSV.js';

function UserList({
  users,
  onUpdate,
  filter = {},
  onSort = (sortByKey) => {},
  pageIndex,
  pageSize,
  loading,
  totalRow,
  labelDb,
}) {
  const downloadRef = useRef(null);
  const [dataToDownload, setDataToDownload] = useState([]);

  let titleMember;

  const csvMember = [];
  users.map((user) => {
    const value = { ...user };
    delete value.agent;
    delete value.attributes;
    delete value.operator;
    delete value.images;
    delete value.avatar;
    delete value.states;
    titleMember = Object.keys(value);
    csvMember.push(Object.values(value));
    return user;
  });
  csvMember.unshift(titleMember);

  const history = useHistory();
  const envUICms = useSelector((state) => state.envUICms.data);
  const nameRank = useSelector((state) => state.nameRank.data);
  const admin = useSelector((state) => state.admin.data);
  const bulkConversionButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_BULK_CONVERSION,
  );
  const sendEmailButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_EMAIL_SEND,
  );
  const sendEmailLoopButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_EMAIL_LOOP_SEND,
  );
  const sendNotiButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_NOTIFY_SEND,
  );
  const sendSmsButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_SMS_SEND,
  );
  const sendSmsLoopButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_SMS_LOOP_SEND,
  );
  const csvExportButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_CSV_EXPORT,
  );
  const csvImportButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_CSV_IMPORT,
  );
  const moveToArchiveButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_ARCHIVE_USERS,
  );
  const deleteUserButton = envUICms?.filter(
    (item) => item.link === LINK_MAP_DEFINED_API.USER_SEARCH_DELETE_USERS,
  );

  const [csvImportModalVisible, setCsvImportModalVisible] = useState(false);
  const [isConfirmImportModalOpen, setIsConfirmImportModalOpen] =
    useState(false);
  const [smsVisible, setSmsVisible] = useState(false);
  const [smsLoopVisible, setSmsLoopVisible] = useState(false);
  const [emailVisible, setEmailVisible] = useState(false);
  const [emailLoopVisible, setEmailLoopVisible] = useState(false);
  const [notiVisible, setNotiVisible] = useState(false);
  const [moveToArchiveVisible, setMoveToArchiveVisible] = useState(false);
  const [deleteUserVisible, setDeleteUserVisible] = useState(false);

  const [EnvSysCms, setEnvSysCms] = useState([]);

  const [csvFileUpload, setCsvFileUpload] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getEnvSysCms().then((res) => {
      const { data } = res;
      setEnvSysCms(data.data);
    });
  }, []);

  useEffect(() => {
    return localStorage.removeItem('UserSearchFilter');
  }, []);

  useEffect(() => {
    if (csvFileUpload) {
      setIsConfirmImportModalOpen(true);
    }
  }, [csvFileUpload]);

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const SetAllSearchUserResultToStorage = async () => {
    const userSearchFilterText = localStorage.getItem('UserSearchFilter');
    const userSearchFilter = userSearchFilterText
      ? JSON.parse(userSearchFilterText)
      : {};
    const { data } = await getUsers({
      ...userSearchFilter,
      sortBy: 'lastlogin',
      orderBy: 'DESC',
      sort: { lastlogin: 'DESC' },
      pageIndex: 1,
      pageSize: 9999999,
    });
    const userIds = data.data.map((item) => item.id);
    localStorage.setItem('userIds', userIds);
  };

  const handleClickSms = () => {
    if (EnvSysCms) {
      const mailConfig = EnvSysCms.find((t) => t.content === 'sms_max');
      if (mailConfig?.max < totalRow) {
        return toast.error(
          '送信数制限内でSMSを送信して下さい。制限数：' + mailConfig?.max,
        );
      }
    }
    setSmsVisible(true);
  };
  const handleClickSmsLoop = () => {
    setSmsLoopVisible(true);
  };

  const handleClickEmail = () => {
    if (EnvSysCms) {
      const mailConfig = EnvSysCms.find((t) => t.content === 'mail_max');
      if (mailConfig?.max < totalRow) {
        return toast.error(
          '送信数制限内でメールを送信して下さい。制限数：' + mailConfig?.max,
        );
      }
    }
    setEmailVisible(true);
  };
  const handleClickEmailLoop = () => {
    setEmailLoopVisible(true);
  };

  const handleClickNoti = () => {
    if (EnvSysCms) {
      const mailConfig = EnvSysCms.find((t) => t.content === 'push_max');
      if (mailConfig?.max < totalRow) {
        return toast.error(
          '送信数制限内でPUSHを送信して下さい。制限数：' + mailConfig?.max,
        );
      }
    }
    setNotiVisible(true);
  };

  const handleClickDeleteUser = async () => {
    await SetAllSearchUserResultToStorage();
    setDeleteUserVisible(true);
    setMoveToArchiveVisible(false);
  };

  const handleClickMoveToArchive = async () => {
    await SetAllSearchUserResultToStorage();
    setDeleteUserVisible(true);
    setMoveToArchiveVisible(true);
  };

  const handleCloseSms = () => {
    setSmsVisible(false);
  };
  const handleCloseSmsLoop = () => {
    setSmsLoopVisible(false);
  };

  const handleCloseEmail = () => {
    setEmailVisible(false);
  };
  const handleCloseEmailLoop = () => {
    setEmailLoopVisible(false);
  };

  const handleCloseNoti = () => {
    setNotiVisible(false);
  };

  const handleCloseDeleteUser = () => {
    setMoveToArchiveVisible(false);
    setDeleteUserVisible(false);
    onUpdate(true);
  };

  const handleEditUsers = async () => {
    await SetAllSearchUserResultToStorage();
    history.push('/user/edit/multi');
  };

  const handleCloseConfirmImportCsv = () => {
    setIsConfirmImportModalOpen(false);
  };

  const checkNameRank = (item) => {
    return nameRank.find((rank) => rank.id === item)?.markWord;
  };

  const charaStatusToText = (item) => {
    return convertCharaStatusText(+item);
  };

  const downloadCsv = async (event) => {
    try {
      const userSearchFilterText = localStorage.getItem('UserSearchFilter');
      const userSearchFilter = userSearchFilterText
        ? JSON.parse(userSearchFilterText)
        : {};

      const response = await getUserExport({
        ...userSearchFilter,
        sortBy: 'lastlogin',
        orderBy: 'DESC',
        sort: { lastlogin: 'DESC' },
        pageIndex: 1,
        pageSize: 9999999,
      });

      setDataToDownload(() => response.data?.data ?? []);

      // click the CSVLink component to trigger the CSV download
      downloadRef.current.link.click();
    } catch (e) {
      toast.error('失敗しました');
    }
  };

  const handleImportCSV = async (csvFileUpload) => {
    try {
      if (csvFileUpload) {
        const formData = new FormData();
        formData.append('importFileTest', csvFileUpload.file);
        dispatch(importCSV(formData));
      }
    } catch (e) {
      if (e.response?.data?.hashCode === 'CSV_INVALID_DATA') {
        try {
          if (e.response?.data.message) {
            const dt = JSON.parse(e.response.data.message);
            let rs = ['失敗しました'];
            for (const r of dt) {
              if (r?.error) {
                rs.push(r?.error);
              }
            }

            toast.error(rs.join('\n'), {
              enableHtml: true,
              className: 'toast-new-line',
            });
            return;
          }
        } catch (e) {
          // TODO
        }
      }
      if (e.response?.data?.errorCode === 'CSV_INVALID_CLOSING_QUOTE') {
        toast.error('失敗しました \n ' + e.response?.data.message, {
          enableHtml: true,
          className: 'toast-new-line',
        });
        return;
      }
      toast.error('失敗しました');
    }
  };

  return (
    <div>
      {csvFileUpload && (
        <ConfirmImportModal
          isOpen={isConfirmImportModalOpen}
          onClose={handleCloseConfirmImportCsv}
          fileData={csvFileUpload}
          onSubmit={() => handleImportCSV(csvFileUpload)}
        />
      )}
      <FileUploadModal
        showDialogDragDrop={csvImportModalVisible}
        setShowDialogDragDrop={setCsvImportModalVisible}
        fileData={csvFileUpload}
        setFileData={setCsvFileUpload}
        acceptFileType=".csv"
        dataInfoHeadText="UPLOAD CSV"
      />
      {smsVisible && (
        <SendSmsModal
          visible={smsVisible}
          onClose={handleCloseSms}
          users={users}
          filter={filter}
        />
      )}
      {smsLoopVisible && (
        <SendSmsLoopModal
          visible={smsLoopVisible}
          onClose={handleCloseSmsLoop}
          users={users}
          filter={filter}
        />
      )}
      {emailVisible && (
        <SendEmailModal
          visible={emailVisible}
          onClose={handleCloseEmail}
          users={users}
          filter={filter}
        />
      )}
      {emailLoopVisible && (
        <SendEmailLoopModal
          visible={emailLoopVisible}
          onClose={handleCloseEmailLoop}
          users={users}
          filter={filter}
        />
      )}
      {notiVisible && (
        <SendNotiModal
          visible={notiVisible}
          onClose={handleCloseNoti}
          users={users}
          filter={filter}
        />
      )}
      <RemoveMultipleUsersModal
        visible={deleteUserVisible}
        onClose={handleCloseDeleteUser}
        moveToArchive={moveToArchiveVisible}
      />
      <React.Fragment>
        <Row style={{ alignItems: 'flex-end' }}>
          <Col xs={12} style={{ clear: 'both', marginBottom: 10 }}>
            {(admin?.memberType === 0 ||
              deleteUserButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  deleteUserButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleClickDeleteUser
                    : ''
                }
                className="btn-red btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {deleteUserButton[0]?.content}
                </span>
              </Button>
            )}
            {(admin?.memberType === 0 ||
              moveToArchiveButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  moveToArchiveButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleClickMoveToArchive
                    : ''
                }
                className="btn-red btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {moveToArchiveButton[0]?.content}
                </span>
              </Button>
            )}
            {(admin?.memberType === 0 ||
              sendSmsButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  sendSmsButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleClickSms
                    : ''
                }
                className="btn-blue btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {sendSmsButton[0]?.content}
                </span>
              </Button>
            )}
            {(admin?.memberType === 0 ||
              sendEmailButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  sendEmailButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleClickEmail
                    : ''
                }
                className="btn-blue btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {sendEmailButton[0]?.content}
                </span>
              </Button>
            )}
            {(admin?.memberType === 0 ||
              sendNotiButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  sendNotiButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleClickNoti
                    : ''
                }
                className="btn-blue btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {sendNotiButton[0]?.content}
                </span>
              </Button>
            )}
            {(admin?.memberType === 0 ||
              sendEmailLoopButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  sendEmailLoopButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleClickEmailLoop
                    : ''
                }
                className="btn-blue btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {sendEmailLoopButton[0]?.content}
                </span>
              </Button>
            )}
            {(admin?.memberType === 0 ||
              sendSmsLoopButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  sendSmsLoopButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleClickSmsLoop
                    : ''
                }
                className="btn-blue btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {sendSmsLoopButton[0]?.content}
                </span>
              </Button>
            )}
            {(admin?.memberType === 0 ||
              bulkConversionButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <Button
                size="small"
                variant="contained"
                onClick={
                  admin?.memberType === 0 ||
                  bulkConversionButton[0]?.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                    ? handleEditUsers
                    : ''
                }
                className="btn-blue btn-custom float-right mr-3"
              >
                <span className="btn-wrapper--label">
                  {bulkConversionButton[0]?.content}
                </span>
              </Button>
            )}

            {(admin?.memberType === 0 ||
              csvExportButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <>
                <Button
                  onClick={downloadCsv}
                  size="small"
                  variant="contained"
                  className="btn-blue btn-custom float-right mr-3"
                >
                  <span className="btn-wrapper--label">
                    {csvExportButton[0]?.content}
                  </span>
                </Button>
                <CSVLink
                  className="d-none"
                  separator={'|'}
                  filename="member-user.csv"
                  data={dataToDownload}
                  ref={downloadRef}
                />
              </>
            )}
            {(admin?.memberType === 0 ||
              csvImportButton[0]?.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
              <>
                <Button
                  onClick={() => setCsvImportModalVisible(true)}
                  size="small"
                  variant="contained"
                  className="btn-blue btn-custom float-right mr-3"
                >
                  <span className="btn-wrapper--label">
                    {csvImportButton[0]?.content}
                  </span>
                </Button>
              </>
            )}
          </Col>
          <Col xs={12}>
            <h6 className="color-900 ml-3">検索結果 {totalRow}人</h6>
          </Col>
        </Row>
        <MuiThemeProvider theme={tableNewTheme}>
          <Card className="scroll-x-on-top">
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="電話番号"
                      name="phoneNumber"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell className="text-center">画像</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text={labelDb?.label_nickName?.content}
                      name="nickName"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={
                        filter.sort?.gender && {
                          ...filter.sort,
                          gender:
                            filter.sort?.gender === 'ASC' ? 'DESC' : 'ASC',
                        }
                      }
                      text="性別"
                      name="gender"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="states.label"
                      text="都道府県"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>年齢</TableCell>
                  <TableCell>ランク</TableCell>
                  <TableCell>会員種類</TableCell>
                  <TableCell>会員カテゴリー</TableCell>
                  {/* <TableCell>キャラクター</TableCell>
                  <TableCell>オペNum</TableCell>
                  <TableCell>会社Num</TableCell> */}
                  <TableCell>
                    <SortArrows
                      sortObject={
                        filter.sort?.ageverification && {
                          ...filter.sort,
                          ageverification:
                            filter.sort?.ageverification === 'ASC'
                              ? 'DESC'
                              : 'ASC',
                        }
                      }
                      name="ageverification"
                      text="年齢認証"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="registerDate"
                      onClick={onSort}
                      text="登録日"
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="ログイン"
                      name="lastlogin"
                      onClick={onSort}
                    />
                  </TableCell>
                </TableRow>

                {users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(pageIndex - 1) * pageSize + index + 1}
                    </TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell className="text-center">
                      <div>
                        <Link
                          to={`/user/${user.id}/detail`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => saveLocalStore(user.id)}
                        >
                          <img
                            className="imgRound"
                            src={
                              user.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE
                                ? UserDelete
                                : user.avatar
                                  ? user.avatar.url
                                  : NoPhoto
                            }
                            alt=""
                          />
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>{user.nickName}</TableCell>
                    <TableCell>{Util.genderIdToText(user.gender)}</TableCell>
                    <TableCell>{user.states?.label}</TableCell>
                    <TableCell>
                      <time>
                        {Util.calcAge(
                          Util.timestampToDate(user.birthday, 'YYYY-MM-DD'),
                        )}
                      </time>
                    </TableCell>
                    <TableCell>{checkNameRank(user.rank)}</TableCell>
                    <TableCell>
                      {Util.memberTypeToText(user.memberType)}
                    </TableCell>
                    <TableCell>
                      {convertMemberCategoryTypeText(user.memberCategory)}
                    </TableCell>
                    {/* <TableCell>
                      {charaStatusToText(user.charaStatus)}
                    </TableCell>
                    <TableCell>{user.operator?.operatorNumber}</TableCell>
                    <TableCell>{user.agent?.agentNumber}</TableCell> */}
                    <TableCell>
                      {convertInspectionStatusText(+user.ageverification)}
                    </TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(user.registerDate, 'YYYY/MM/DD')}
                      </time>
                    </TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(
                          user.lastlogin,
                          'YYYY/MM/DD HH:mm:ss',
                        )}
                      </time>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </MuiThemeProvider>
      </React.Fragment>
    </div>
  );
}

export default UserList;
